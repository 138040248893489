import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGithub, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faExternalLink, faNewspaper } from '@fortawesome/pro-solid-svg-icons'

import Header from './header'
import Footer from './footer'
import '../styles/style.scss'

library.add(
    faGithub,
    faTwitter,
    faYoutube,
    faExternalLink,
    faNewspaper
)

const Layout = ({ noHeader, footerLock, children }) => {
    return (
        <>
            {!noHeader && <Header />}
            <main id="main-content">{children}</main>
            <Footer locked={footerLock} />
        </>
    )
}

export default Layout