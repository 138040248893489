import React, { useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Header = () => {
    const [active, setActive] = useState(false)

    return (
        <nav className="navbar is-transparent is-black" role="navigation" aria-label="main-navigation">
            <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                    <StaticImage 
                        src="../images/njlogo-1.png"
                        placeholder="tracedSVG"
                        alt="newtjam"
                        style={{ width: "6rem" }}
                    />
                </Link>

                <a role="button" className={"navbar-burger burger " + (active ? "is-active" : "")} aria-label="menu" aria-expanded="false" data-target="navbar" onClick={() => setActive(!active)}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbar" className={"navbar-menu " + (active ? "is-active" : "")}>
                <div className="navbar-end">
                    <Link to="/" className="navbar-item is-tab" activeClassName="is-active">
                        Home
                    </Link>

                    <Link to="/about" className="navbar-item is-tab" activeClassName="is-active">
                        About
                    </Link>

                    <Link to="/projects" className="navbar-item is-tab" activeClassName="is-active">
                        Projects
                    </Link>

                    <Link to="/contact" className="navbar-item is-tab" activeClassName="is-active">
                        Contact
                    </Link>
                </div>
            </div>
        </nav>
    )
}

export default Header