import React from 'react'
import { Link } from 'gatsby'

const Footer = ({ locked }) => {
    return (
        <footer className={"footer " + (locked && "locked")}>
            <div className="content has-text-centered">
                <p>Built with Gatsby, Netlify CMS, and Bulma by newtjam.</p>
                <p>Check out the specifics <Link to="/projects/portfolio">here</Link>.</p>
            </div>
        </footer>
    )
}

export default Footer